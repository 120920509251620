<template>
    <page-info-nav heading="FAQ"></page-info-nav>
    <div class="p-3" id="faq"></div>
    <!-- <accordion-wrapper :id="parentId" class="container-lg mt-5 px-md-5">

        <accordion-item id="ac1" :parent-id="parentId" heading="Free Trial">
            <accordion-wrapper id="child-1">
                <accordion-item
                    id="ac-ci-1-1"
                    parent-id="child-1"
                    heading="Is there any trial period available for The Hindu and BusinessLine e-Paper?"
                >You will be eligible for a one-time free trial as soon as you sign up. The free trial is valid for 7 days.</accordion-item>
                <accordion-item
                    id="ac-ci-1-2"
                    parent-id="child-1"
                    heading="Will I be able to download the e-Paper during my trial period?"
                >During trial period, users cannot download the newspaper pages and edition, while they can download individual articles in both html and PDF formats</accordion-item>
                <accordion-item
                    id="ac-ci-1-3"
                    parent-id="child-1"
                    heading="Will I be able to access the archives of the e-Paper during my trial period?"
                >Users can read all the pages during their trial period. However, they cannot view the archives before their subscription date</accordion-item>
            </accordion-wrapper>
        </accordion-item>

        <accordion-item id="ac2" :parent-id="parentId" heading="View Daily e-Paper">
            <accordion-wrapper id="child-2">
                <accordion-item
                    id="ac-ci-2-1"
                    parent-id="child-2"
                    heading="How to change Password?"
                >
                    <p>After login to e-Paper,</p>
                    <ol>
                        <li>Click on the user icon on the top right of the tool bar on the top</li>
                        <li>Select ‘Change Password’ From the menu</li>
                        <li>In the pop up form type your old and new password</li>
                        <li>Click on the ‘Change Password button.</li>
                    </ol>
                </accordion-item>
            </accordion-wrapper>
        </accordion-item>

        <accordion-item id="ac3" :parent-id="parentId" heading="Subscription">
            <accordion-wrapper id="child-3">
                <accordion-item
                    id="ac-ci-3-1"
                    parent-id="child-3"
                    heading="I have an active subscription. What will happen if I renew before the due date? Will the remaining days of my current subscription expire?"
                >The new subscription period will start only after the end of your current subscription. So, the remaining days will not get expired. For example, if your monthly plan gets over on 31st of January and you choose to recharge on 26th of January. The new plan will start only from 1st of February.</accordion-item>
                <accordion-item
                    id="ac-ci-3-2"
                    parent-id="child-3"
                    heading="Why are you charging for e-Paper?"
                >There's a lot of effort that goes into reporting, publishing, and distributing news truthfully and fairly. The Hindu's seamless coverage of news is a result of the round-the-clock efforts of hundreds of journalists - reporters, editors, fact-checkers, photographers, videographers, publishers - as well as many support staff. In addition to this we do incur costs associated with Software development, Bandwidth, AMC cost and Maintenance of database & server.</accordion-item>
                <accordion-item
                    id="ac-ci-3-3"
                    parent-id="child-3"
                    heading="How to contact support?"
                >You have complete control over your subscription and can contact us at epapercare@thehindu.co.in to change or cancel at any time.
                </accordion-item>
                <accordion-item
                    id="ac-ci-3-4"
                    parent-id="child-3"
                    heading="I have made the payment, but my plan is not active"
                >
                <p>If your payment has gone through but you’re still unable to activate your plan, send us an email with the following details:</p>
                <ol>
                    <li>Transaction ID</li>
                    <li>Plan Name</li>
                    <li>Your Registered ID</li>
                    <li>Date of transaction</li>
                    <li>Your Bank Name</li>
                    <li>Mode of transaction (Debit Card, Credit Card, e-Wallet)</li>
                </ol>
                <p>Please send these details to epapercare@thehindu.co.in</p>
                </accordion-item>
                <accordion-item
                    id="ac-ci-3-5"
                    parent-id="child-3"
                    heading="What are the advantages of e-Paper?"
                >
                <ol>
                    <li>The printed edition will be available in full.</li>
                    <li>The individual articles can be seen in pdf/ image/text format and fully searchable.</li>
                    <li>Newspaper column format has been preserved.</li>
                    <li>Classified sections are fully searchable and easy to preserve.</li>
                    <li>Ability to provide an easy access for back dated edition pages (10 days current + 60 days’ archive)</li>
                    <li>The Read offline feature to download the entire edition in one go and can be preserved for future reference.</li>
                    <li>Ability to send article by email. (The recipient does not require subscription)</li>
                </ol>
                </accordion-item>
            </accordion-wrapper>
        </accordion-item>

        <accordion-item id="ac4" :parent-id="parentId" heading="All Access Pass">
            <accordion-wrapper id="child-4">
                <accordion-item
                    id="ac-ci-4-1"
                    parent-id="child-4"
                    heading="What is All Access pass?"
                >
                    <p>All Access Pass enables you to access all the Digital subscriptions of The Hindu Group in One Single Discounted Plan. Once you subscribe, you get 1-year unlimited access to the following products:</p>
                    <ol>
                        <li>
                            The Hindu e-paper: Read The Hindu newspaper in a digital format anytime, anywhere.
                        </li>
                        <li>
                            The Hindu BusinessLine e-paper: Read The Hindu BusinessLine newspaper in a digital format at your own convenience.
                        </li>
                        <li>
                            The Hindu: Experience the ad-free version of The HIndu with personalised recommendations, daily briefings and much more.
                        </li>
                        <li>
                            The Hindu BusinessLine: Get your daily dose of business news along with full access to signature sections such as BL Portfolio, BLink, Clean Tech, Emerging Entrepreneurs & Editorials.
                        </li>
                        <li>
                            Sportstar: Browse through our list of esteemed columnist articles, get full access to our archives along with access to our seasonal posters and much more.
                        </li>
                        <li>
                            Frontline: Get access to premium articles with an in-depth analysis of issues and events within Indian and around the world.
                        </li>
                        <li>
                            The Hindu Crossword+: Get unlimited access to all puzzles and archival content. Crosswords and games from The Guardian, The Hindu, Sportstar, One-down puzzles, Sudoko and much more.
                        </li>
                    </ol>
                </accordion-item>
                <accordion-item
                    id="ac-ci-4-2"
                    parent-id="child-4"
                    heading="Does the All Access Pass include the print edition?"
                >The All access Pass is a Digital Pass and does not include the print subscription.</accordion-item>
            </accordion-wrapper>
        </accordion-item>
    </accordion-wrapper> -->
</template>

<script>

import PageInfoNav from '@/components/PageInfoNav';
// import AccordionWrapper from '@/components/AccordionWrapper';
// import AccordionItem from '@/components/AccordionItem';

export default {
    components: {
        PageInfoNav,
        // AccordionWrapper,
        // AccordionItem
    },
    // data() {
    //     return {
    //         parentId: "faq-top-level"
    //     }
    // }
}

</script>

<style scoped>
.accordion-body {
    font-size: 12px;
}
</style>