<template>
    <the-header></the-header>
    <div class="p-3" id="premium-ebook"></div>
</template>

<script>
import TheHeader from '@/components/commonHeader.vue'
export default {
    components: {
        TheHeader
    }
}
</script>

<style scoped>
body {
    background-color: #ffffff !important;
}
</style>